<template>
    <div class="group">
        <div class="content">
             <div class="tree">
                 <p class="company">公司组织架构</p>
                 <a-tree v-show="tabsActive==0" :treeData="treeData" @select="_treeSelect"/>
             </div>
             <div class="list">
                 <ul class="list-title">
                     <li>
                         <span>开通体验</span>
                         <span class="typeNum">{{SummaryData.in_count}}</span>
                     </li>
                     <li>
                         <span>已体验</span>
                         <span class="typeNum">{{SummaryData.InedPeopleCount}}</span>
                     </li>
                     <!-- <li>
                         <span>客户参与率</span>
                         <span class="typeNum">{{SummaryData.InRate}}</span>
                     </li>
                     <li>
                         <span>总完成人数</span>
                         <span class="typeNum">{{SummaryData.total_complete}}</span>
                     </li>
                     <li>
                         <span>完成率</span>
                         <span class="typeNum">{{SummaryData.completeRate}}</span>
                     </li> -->
                 </ul>
                 <div style="clear: both"></div>
                 <div class="search" style="display: block">
                     <a-range-picker  @change="_onChange" :default-value="[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]" :format="dateFormat"/>
                     <a-button class="search-style" 重置</a-button>
                     <a-button type="primary" style="margin-left: 10px;" @click="_search">查询</a-button>
                 </div>


                 <div>
                    <a-table :columns="columns" :dataSource="StatData" :pagination='false' style="margin-top:10px;border:1px solid #efefef">
            
                    </a-table> 
                 </div>
            </div>

        
    </div>
</div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "CloudcoachReport",
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                tabsActive:0,
                selectOrgId:'',
                StartDate:'',
                EndDate:'',
                treeData:[],
                columns:[
                    { title: '-', width: 100, dataIndex: 'type', fixed: 'left' },
                    { title: '完成人数', dataIndex: 'CompletePeopleCount', key: '2' },
                    // { title: '完成率(%)', dataIndex: 'CompleteRate', key: '3' },
                    { title: '累计时长(分)', dataIndex: 'CumDuration', key: '4' },
                    { title: '人均时长(分)', dataIndex: 'AvePeopleDuration', key: '5' },
                    { title: '人均周次数', dataIndex: 'AvePeopleWeekCount', key: '6' },
                    { title: '平均正确率(%)', dataIndex: 'AveCorrectRate', key: '7' },
                    { title: '已完成题量', dataIndex: 'CompletedQuestionCount', key: '8' },
                  ],
                StatData:[],
                SummaryData:[],
                dateFormat:"YYYY-MM-DD",
            }
        },
        methods:{
            moment
        },
        created(){
            this.StartDate = moment(new Date()).add(-1, 'days').format(this.dateFormat);
            this.EndDate = moment(new Date()).format(this.dateFormat);
            this._getOrgList();
            this._getStatData();
        },
        mounted(){
            
        },
        computed: {


        },
        methods:{
            moment,
            _tabsActive(i){
                this.tabsActive = i;
            },
            _getOrgList(){
                this.$axios.get(1072,{
                    Pid:'',
                },res=>{
                    if (res.data.code == 1) {
                        this.treeData = res.data.list;
                    }
                })
            },
            _getStatData(){
                this.$message.loading('加载中...');
                this.$axios.get(7000008,{
                    SchoolId:this.selectOrgId,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                },res=>{
                    if (res.data.code == 1) {
                        this.StatData = res.data.data.list;
                        this.SummaryData = res.data.data.SummaryData;
                    } else {
                        this.StatData = [];
                        this.SummaryData = [];
                    }
                    this.$message.destroy();
                })
            },
            _treeSelect(key,e){
                this.selectOrgId = key[0];
                this._getStatData();
            },
            _onChange(v,dateString){
                this.StartDate = dateString[0];
                this.EndDate = dateString[1];
            },
            _search(){
                this._getStatData();
            },
            _rest(){
                this.StartDate = '';
                this.EndDate = '';
            },
        }
    }
</script>

<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;

        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }
    .content{
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree{
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #DFE0E6;
            border-radius: 5px;
            .company{
                color: #242424;
                font-size:14px;
            }
        }
        .list{
            padding: 15px;
            background: #ffffff;
            margin-left: 20px;
            flex: 1;
            padding-right: 75px;
            .list-title{
                /*width: 400px;*/
                li{
                    width: 15.8%;
                    margin-right: 1%;
                    float: left;
                    border:1px solid rgba(224,234,244,1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span{
                        display: block;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                    }
                    .typeNum{
                        color:rgba(102,102,102,1);
                        font-size: 16px;
                    }
                }
                .title-active{
                    border:1px solid rgba(41,148,255,1);
                }
                li:last-child{
                    margin-right: 0;
                }
            }

        }
    }
    .search{
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style{
            margin-right: 10px;
        }
    }
    .hook{
        color:rgba(41,148,255,1);
        cursor: pointer;
    }
</style>